<!-- eslint-disable array-callback-return -->
<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col
          md="12"
          xl="9"
        >
          <b-row class="match-height">
            <!-- supplier -->
            <b-col md="6">
              <b-card :title="$t('Supplier')">
                <div class="title bold mb-1">
                  Fashion
                </div>
                <span>51 Yên Thế, Phường 2, Tân Bình, TP. Hồ Chí Minh</span>
                <div class="d-flex justify-content-between mt-1">
                  <span>Còn nợ: <strong>3.000.000 đ</strong></span>
                  <router-link to="#">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <!-- warehouse -->
            <b-col md="6">
              <b-card :title="$t('Kho Nhập')">
                <validation-provider
                  #default="{ errors }"
                  name="Warehouse"
                  rules="required"
                >
                  <v-select
                    v-model="model.warehouse_id"
                    :options="warehouseOptions"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Add New Warehouse') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="loadSupplier"
                      >
                        <feather-icon
                          icon="RefreshCwIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <span>51 Yên Thế, Phường 2, Tân Bình, TP. Hồ Chí Minh</span>
                <div class="d-flex justify-content-between mt-1">
                  <span>0982879920</span>
                  <router-link to="#">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                :title="$t('Product')"
                class="mih-100"
              >
                <!-- <div class="mt-1">
                  <vue-good-table
                    :columns="columnsDone"
                    :rows="rowsDone"
                    class="custom-good-table-row-head"
                    style-class="vgt-table"
                    :group-options="{
                      enabled: true,
                      headerPosition: 'top'
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }"
                    @on-selected-rows-change="selectionChanged"
                  >
                    <template
                      slot="table-header-row"
                      slot-scope="props"
                    >
                      <b-avatar
                        :key="props.row.img"
                        :src="props.row.img"
                        variant="light-info"
                        rounded
                        class="p-avatar"
                      />
                      <span class="my-fancy-class">
                        {{ props.row.label }}
                      </span>
                    </template>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <div
                        v-if="props.column.field === 'name'"
                        class="avatar-name mx-auto"
                      >
                        <div class="name-child">
                          {{ props.row.name }}
                        </div>
                        <div class="sku">
                          <strong>SKU:&nbsp;</strong>{{ props.row.sku }}
                        </div>
                      </div>
                      <div
                        v-else-if="props.column.field === 'stock'"
                        class="count-w mx-auto"
                      >
                        {{ props.row.stock }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'quantity'"
                        class="count-w mx-auto"
                      >
                        <b-input-group
                          append="/11"
                          class="input-group-merge"
                        >
                          <b-form-input v-model="props.row.quantity" />
                        </b-input-group>
                      </div>
                      <div
                        v-else-if="props.column.field === 'price'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.price" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'discount'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.discount" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'total'"
                        class="text-end"
                      >
                        {{ unitFormatOriginal(props.row.total) }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'close'"
                        class="d-flex text-center"
                      >
                        <b-button
                          variant="flat-danger"
                          class="text-danger btn-icon rounded-circle"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </template>
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap ">
                            Showing 1 to
                          </span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10','30','100']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </div> -->
                <items
                  v-model="rowsDone"
                  @sumQuantity="sumQuantity"
                  @sumPrice="sumPrice"
                  @deleteVariant="deleteVariant"
                />
              </b-card>
              <b-card
                title="Ghi Chú"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="text"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                />
                <div class="btn-submit d-flex justify-content-end mt-1">
                  <b-button
                    variant="primary"
                    type="button"
                    class="ml-2"
                    to="add"
                  >
                    {{ $t('Lưu') }}
                  </b-button>
                </div>
              </b-card>
              <b-card title="Lịch sử">
                <div class="input-note ml-1">
                  <div class="icon-user-chat">
                    <feather-icon
                      icon="UserIcon"
                      size="40"
                    />
                  </div>
                  <div class="date">
                    16/07/2022
                  </div>
                  <b-input-group class="pb-5">
                    <b-form-input placeholder="Thêm nội dung ghi chú" />
                    <b-input-group-append>
                      <b-button variant="primary">
                        <feather-icon
                          icon="SendIcon"
                          size="20"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <app-timeline>
                  <app-timeline-item>
                    <div class="d-flex flex-sm-row flex-wrap justify-content-between mb-1">
                      <h6>
                        Phiếu được tạo bởi
                        <router-link
                          to="/user/edit"
                          class="text-primary"
                        >
                          Anh Huân
                        </router-link>
                      </h6>
                      <div class="date-cm">
                        11:02 SA
                      </div>
                    </div>
                  </app-timeline-item>
                </app-timeline>
                <hr>
                <b-button
                  variant="danger"
                  type="button"
                  to="add"
                  class="mt-1"
                >
                  {{ $t('Kết thúc') }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="12"
          xl="3"
        >
          <div class="position-sticky top">
            <b-card>
              <div class="title-nv">
                Nhân viên xử lí
              </div>
              <div class="id-manh d-flex align-items-center text-primary">
                <feather-icon
                  icon="UserIcon"
                  size="14"
                />
                <div class="name">
                  <router-link
                    to="/user/edit"
                    class="text-primary"
                  >
                    Anh Huân
                  </router-link>
                </div>
              </div>
              <div class="title-nv">
                Ngày nhận hàng dự kiến
              </div>
              <div class="id-manh d-flex align-items-center text-primary">
                <feather-icon
                  icon="CalendarIcon"
                  size="14"
                />
                <div class="name">
                  16/07/2022
                </div>
              </div>
            </b-card>
            <b-card
              title="Chi phí mua hàng"
            >
              <div class="d-flex justify-content-between">
                <span>Tổng số lượng đặt</span>
                <span>22</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>Tổng tiền hàng</span>
                <span>{{ unitFormatOriginal(120000) }}</span>
              </div>
              <hr>
              <!-- <div class="d-flex justify-content-between my-1">
                <strong>Cần trả nhà cung cấp</strong>
                <span>{{ unitFormatOriginal(120000) }}</span>
              </div> -->
              <div class="d-flex justify-content-between my-1">
                <strong>Tổng tiền mua hàng</strong>
                <span>{{ unitFormatOriginal(0) }}</span>
              </div>
              <hr>
              <div
                class="d-flex justify-content-between"
              >
                <strong
                  v-b-modal.modal-pay-supplier
                  class="text-primary"
                >Trả nhà cung cấp</strong>
                <span>{{ unitFormatOriginal(120000) }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <strong>Còn nợ</strong>
                <span>{{ unitFormatOriginal(0) }}</span>
              </div>
              <div class="text-center mt-2 w-100">
                <b-button
                  variant="primary"
                  type="submit"
                  class="w-100"
                  @click.prevent="validationForm"
                >
                  {{ $t('Stock Input') }}
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- modal add selected -->
    <b-modal
      id="modal-pay-supplier"
      title="Thanh toán cho nhà cung cấp"
      cancel-title="Đóng"
      cancel-variant="outline-secondary"
      ok-title="Xác Nhận"
      no-close-on-backdrop
    >
      <b-card-text>
        <div class="title-price">
          <h5>Tông tiền cần trả nhà cung cấp</h5>
          <div class="price-modal text-primary">
            {{ unitFormatOriginal(3750000) }}
          </div>
        </div>
        <div class="list-payment mt-1">
          <div class="d-flex justify-content-between mb-1">
            <b-form-checkbox
              v-model="SelectedRd"
              name="some-radios"
              value="1"
            >
              Tiền mặt
            </b-form-checkbox>
            <b-form-input class="w-175px" />
          </div>
          <div class="mb-1">
            <b-form-checkbox
              v-model="SelectedRd"
              name="some-radios"
              value="2"
            >
              Chuyển khoản
            </b-form-checkbox>
            <div
              v-for="(item, index) in pay"
              :key="index"
              class="d-flex justify-content-between align-items-center ml-2 mt-1"
            >
              <v-select
                v-model="pay[index].selected"
                :options="item.bankOptions"
                label="name"
                input-id="invoice-data-client"
                :clearable="false"
                class="w-50"
                :class="pay[index].selected ? 'd-none' : ''"
                placeholder="Chọn tài khoản"
                @input.native="handleSelectPay(index)"
              >
                <template #list-header>
                  <a
                    class="add-new-client-header d-flex align-items-center my-50"
                    tag="li"
                    href="/supplier/add"
                    target="_blank"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    <span class="align-middle ml-50">{{ $t('Add New Supplier') }}</span>
                  </a>
                  <li
                    class="add-new-client-header d-flex align-items-center my-50"
                    @lick="loadSupplier"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      size="16"
                    />
                    <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                  </li>
                </template>
              </v-select>
              <div :class="!pay[index].selected ? 'd-none' : ''">
                {{ item.name }}
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <b-form-input
                  v-model="item.value"
                  class="w-175px mx-1"
                />
                <feather-icon
                  icon="XIcon"
                  @click="handleDelete(index)"
                />
              </div>
            </div>
            <div
              class="text-primary mt-2 ml-2 cursor-pointer"
              @click="handleAddPay"
            >+ Thêm tài khoản</div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </validation-observer>
</template>

<script>
/* eslint-disable array-callback-return */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  // BFormCheckbox,
  BCard,
  // BCardHeader,
  // BAvatar,
  // BPagination,
  // BFormSelect,
  BCardText,
  // BFormRadio,
  // BBadge,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
// import { VueGoodTable } from 'vue-good-table'
// import Treeselect from '@riophae/vue-treeselect'
// import vrcode from '@ispa.io/vrcode'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Items from './Items.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    // BFormInput,
    BForm,
    // BBadge,
    // BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    BCard,
    // BCardHeader,
    // BAvatar,
    // VueGoodTable,
    // BPagination,
    // BFormSelect,
    vSelect,
    // Treeselect,
    // vrcode,
    BCardText,
    // BFormRadio,
    BFormTextarea,
    AppTimeline,
    AppTimelineItem,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    Items,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      text: '',
      avatarText,
      required,
      supplierOptions: [],
      SelectedRd: [],
      bankOptions: [
        {
          id: 1,
          name: 'Chuyển khoản qua ngân hàng',
        },
        {
          id: 2,
          name: 'NCC iFashion - 009000099',
        },
      ],
      selected: 0,
      warehouseOptions: [
        {
          id: 1,
          label: 'Kho mặc định',
        },
      ],
      model: {
        quantity: null,
        position: null,
        is_active: true,
        supplier_id: null,
      },
      modelPay: [],
      pay: [],
      pageLength: 10,
      // setting col row list be selected
      columns: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Tồn kho',
          field: 'inventory',
          tdClass: 'text-center',
        },
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 4,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 5,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      // setting col row list select
      rowsDone: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 4,
              quantity: 0,
              price: 120000,
              discount: 0,
              total: 1200000,
              count: 10,
              value: 0,
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              stock: 4,
              quantity: 0,
              price: 120000,
              discount: 0,
              total: 1200000,
              count: 10,
              value: 0,
            },
          ],
        },
      ],
      columnsDone: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tồn kho',
          field: 'stock',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Đã nhận/Đặt',
          field: 'quantity',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Đơn giá nhập',
          field: 'price',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Giá giảm trả',
          field: 'discount',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Thành tiền',
          field: 'total',
          tdClass: 'text-right miw-150',
          thClass: 'text-right',
          sortable: false,
        },
      ],
      // setting col row list selected
      rowsAdded: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: '0/11',
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      columnsAdded: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tool',
          field: 'close',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      modalShow: false,
      modalShowItem: false,
      items: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 1,
              id: 11,
              name: 'Xanh/ S/ Thun',
              stock: 5,
              sku: 'A0038687',
              barcode: 'A0038687',
              selected: false,
            },
            {
              parent_id: 1,
              id: 12,
              name: 'Đen/ S/ Thun',
              stock: 5,
              sku: 'A00386877',
              barcode: 'A00386877',
              selected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Áo Khoác cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 2,
              id: 21,
              name: 'Đen/ XL/ Kaki',
              stock: 1,
              sku: 'A00386878',
              barcode: 'A00386878',
              selected: false,
            },
          ],
        },
      ],
      pSelected: [],
      cSelected: [],
      // list tạm thời đã chọn
      selectedArr: [],
    }
  },
  watch: {
    cSelected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  created() {
    this.loadSupplier()
    this.loadDetail()
  },
  methods: {
    handleSelectPay(index) {
      this.pay[index].id = this.pay[index].selected.id
      this.pay[index].name = this.pay[index].selected.name
    },
    handleAddPay() {
      this.pay.push({
        name: '',
        value: 0,
        bankOptions: this.bankOptions,
        selected: null,
      })
    },
    handleDelete(index) {
      this.pay.slice(index, 1)
    },
    handleSelectItem(val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id === val.parent_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].attribute.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d > 0) this.items[parent].indeterminate = true
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d === this.items[parent].attribute.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
    },
    handleSelectAll(val) {
      const index = this.items.find(x => x.id === val.id)
      // eslint-disable-next-line array-callback-return
      index.attribute.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.selected = val.selected
      })
      this.saveTempArr()
    },
    saveTempArr(item, option = {}) {
      // eslint-disable-next-line array-callback-return
      if (option.all) {
        this.selectedArr.map(x => x.id.include(item.id))
      }
    },
    selectionChanged(e) {
      this.selectedArr = e.selectedRows
    },
    async loadSupplier() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/suppliers?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id_number
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/request_goods/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = {
              id: res.data.data.id,
              expected_date: res.data.data.expected_date,
              note: res.data.data.note,
              status: res.data.data.status,
              storage_id: res.data.data.storage_id,
              supplier_id: res.data.data.supplier_id,
              total_price: res.data.data.total_price,
              total_quantity: res.data.data.total_quantity,
              user_id: res.data.data.user_id,
              vat: res.data.data.vat,
              warehouses: [],
            }
            res.data.data.request_goods_product.map(x => {
              this.model.warehouses.push({
                ...x,
                warehouse: {
                  name_attribute: JSON.parse(x.warehouse.name_attribute),
                  product_id: x.warehouse.product_id,
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              phone: this.model.phone,
              address: this.model.address,
              translation: JSON.stringify(translation),
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/supplier`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/warehouse-list/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
.input-note .input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none;
}
.add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;
    cursor: pointer;

    &:hover {
        background-color: rgba($success, 0.12);
    }
}
.custom-good-table-row-head {
    .custom-select {
        width: 100%;
    }
    .b-avatar {
        margin-right: 8px;
    }
    table.vgt-table {
        font-size: 14px !important;
    }
    thead th {
        vertical-align: middle;
        text-align: center;
        // padding-right: 0.75em;
    }
    tbody th.vgt-checkbox-col {
        padding: 0 0.75em 0 2.75em;
        border-right: none;
    }
    td {
        vertical-align: middle !important;
    }
    .p-avatar {
        margin-left: 16px;
    }
    .avatar-name {
        min-width: 200px !important;
        padding-left: 4.25em;

        .name-child {
            color: #333;
            font-weight: 500;
        }
    }
    .count-w {
        max-width: 100px;
        margin: auto;
    }
    .miw-150 {
        min-width: 150px;
    }
    .vgt-table th.vgt-row-header {
        // border-top: 3px solid #ebe9f1;
        border-bottom: none;
        font-size: 14px;
        thead th {
            vertical-align: middle;
            text-align: center;
            padding-right: 0.75em;
            padding-left: 0;
            white-space: nowrap;
        }
        td {
            vertical-align: middle;
        }

        .custom-th-class {
            text-align: end;
        }
    }
}
#modal-show-item {
    table.vgt-table {
        thead {
            display: none;
        }
        tbody td.text-left {
            padding-left: 5.25em;
            border-bottom: none;
        }
        tbody td.text-center {
            border-bottom: none;
        }
    }
}
</style>
<style lang="scss" scoped>
.input-note {
    display: flex;
    align-items: center;
    position: relative;

    .date {
        position: absolute;
        bottom: 2rem;
        left: 2.5rem;
        font-weight: 700;
    }

    .btn {
        padding: 0 1.5rem;
        svg {
            transform: rotate(45deg);
            color: #fff;
        }
    }

    .icon-user-chat {
        position: absolute;
        top: 0;
        left: -18px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        color: #fff;
        background-color: rgb(60, 148, 209);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 5px solid rgb(60, 148, 209);
        z-index: 1;
    }
    .input-group {
        padding-left: 2.5rem;
        border-left: 1px solid #ebe9f1;
    }
}
.code-block {
    &:not(:last-child) {
        margin-right: 16px;
        padding-right: 16px;
        border-right: 1px solid #f2f2f2;
        .code {
            font-size: 18px;
            color: #000;
            font-weight: 500;
        }
    }
    .code {
        margin-top: 8px;
    }
}
.prin-block {
    font-size: 14px;
    line-height: 18px;
    background: #e9ecef !important;
    border-radius: 4px !important;
    display: inline-block;
    border: none;
    height: 38px;
    color: #000 !important;

    &:hover {
        opacity: 0.8;
    }

    .name {
        margin-left: 8px;
    }
}
ul {
    padding: 0;
    margin-top: 1rem;
}
ul li {
    list-style: none;
}
.bg-gray {
    background: #f6f6f6;
    padding: 12px 0;
}
.hover-bg-gray {
    padding: 0.5rem 0;
}
.hover-bg-gray:hover {
    padding: 0.5rem 0;
    background: #f6f6f6;
}
label {
    font-size: 14px;
}
.mih-100 {
    min-height: 350px;
}
.bold {
    font-weight: bold;
}
.title-nv {
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.id-manh {
    margin-bottom: 1rem;
    font-weight: 600;
    .name {
        margin-left: 4px;
    }
}
.title-hc {
    font-weight: 600;
    font-size: 18px;
    padding-top: 8px;
    border-top: 1px solid #d8d6de;
    margin-bottom: 8px;
}
.title-price {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 1px solid #d8d6de;
    margin-bottom: 8px;
}
.w-175px {
    width: 175px;
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
