import { render, staticRenderFns } from "./PurchasingOrderReceives.vue?vue&type=template&id=b1d16e22&scoped=true&"
import script from "./PurchasingOrderReceives.vue?vue&type=script&lang=js&"
export * from "./PurchasingOrderReceives.vue?vue&type=script&lang=js&"
import style0 from "./PurchasingOrderReceives.vue?vue&type=style&index=0&id=b1d16e22&prod&lang=scss&"
import style1 from "./PurchasingOrderReceives.vue?vue&type=style&index=1&id=b1d16e22&prod&lang=scss&scoped=true&"
import style2 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1d16e22",
  null
  
)

export default component.exports